<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          v-if="mode === 'dark'"
          :src="appDarkLogoImage"
          width="150"
          :alt="`${appName} logo`"
        />
        <b-img
          v-else
          :src="appLogoImage"
          width="150"
          :alt="`${appName} logo`"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Verification -->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Verify Account
          </b-card-title>
          <b-card-text class="mb-2">
            A verification code was sent to
            <span v-if="userData && userData.email">
              <strong>{{ userData.email }}</strong>
            </span>
            <span v-else>your email address</span>.
            Enter it below.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-verify-account-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
            >

              <!-- verification code -->
              <b-form-group
                label="Verification Code"
                label-for="verification-code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Verification Code"
                  vid="verification-code"
                  rules="required"
                >
                  <b-form-input
                    id="verification-code"
                    v-model="verificationCode"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                    name="verification-code"
                    placeholder="Verification Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                Verify Account
              </b-button>
              <b-button
                block
                type="button"
                variant="outline-secondary"
                @click="resendCode"
              >
                Resend Verification Code
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link
              @click="logout"
            >
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Verification -->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BLink,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDarkLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
      appDarkLogoImage,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,

      // Verification
      verificationCode: null,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    mode() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  created() {
    if (!this.userData) {
      // send user to login if not logged in
      this.$router.replace({ name: 'auth-login' })
    } else if (this.userData && this.userData.email_verified) {
      // send user to dashboard if already verified
      this.$router.replace('/')
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          useJwt
            .verifyEmail(this.verificationCode)
            .then(() => {
              // update email_verified in userdata
              const newUserData = { ...this.userData, email_verified: true }
              this.userData = newUserData
              localStorage.setItem('userData', JSON.stringify(newUserData))

              // send user to dashboard
              this.$router.replace('/')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${this.userData.fullName}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Your account has been verified.',
                    },
                  })
                })
            })
            .catch(error => {
              console.log('verifyEmail ERROR', error.response.data)
            })
        }
      })
    },
    resendCode() {
      // confirm alert
      if (this.userData.email) {
        this.$swal({
          title: 'Resend Code?',
          html: `A new code will be sent to <strong>${this.userData.email}</strong>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Yes, send it',
          customClass: {
            confirmButton: 'btn btn-secondary',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            const submitData = {
              display_email: this.userData.email,
            }

            useJwt
              .resendVerificationCode(submitData)
              .then(() => {
                // confirm message
                this.$swal({
                  title: 'Sent!',
                  text: 'Please check your email for a new verification code.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
              .catch(error => {
                console.log('resendVerificationCode ERROR', error.response.data)
              })
          }
        })
      } else {
        this.showErrorMessage('User email is missing. Please log in again.')
      }
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },

    // error message
    showErrorMessage(errorMessage) {
      this.$toast(
        {
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: errorMessage,
          },
        },
        {
          timeout: false,
        },
      )
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
